* {
  box-sizing: border-box;
}

body {
  background-color: #33363b;
  color: #e5e5e5;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin: 0px;
  flex: 1;
  box-sizing: border-box;
}

h3 {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #00d2ff;
  font-size: 1rem;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: white;
  font-size: 0.9rem;
  height: 40px;
  width: 100%;
  border: 0;
  background-color: rgba(255, 255, 255, 0.05);
  margin: 8px 0;
  border-radius: 1px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.btn:focus {
  outline: 0;
}

.btn--large {
  height: 50px;
}

.btn--disabled {
  background: repeating-linear-gradient(-45deg, #3d4045, #3d4045 10px, #383b40 10px, #383b40 20px);
  color: #999;
  border: 0;
  opacity: 1;
}

.btn--selected {
  color: #00d2ff;
}

.btn-group {
  display: flex;
  flex-direction: row;
}

.btn-group>.btn:not(:last-child) {
  margin-right: 10px;
}

main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  margin: 0;
}

.app-content {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  /* max-height: 100% - 50;
  overflow-y: hidden;
  max-height: 500px; */
}

/* HEADER */

header {
  background: #26292D;
  color: #00d2ff;
  padding: 10px 20px;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  display: inline-flex;
  color: #efefef;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
}

header h2 {
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: 0;
  letter-spacing: 3px;
  font-weight: 600;
  color: #efefef;
}

header span {
  color: white;
  width: 30px;
}

header span svg {
  color: white;
  fill: white;
}

/* NAV OVERLAY */

.uk-offcanvas-bar {
  width: 100%;
  background-color: #26292D;
}

.uk-nav {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uk-nav, .uk-nav a, .uk-nav a:hover {
  font-size: 1.5rem;
  line-height: 3;
  color: #e5e5e5;
  text-decoration: none;
}

.uk-nav li {
  display: inline-flex;
  align-items: center;
}

.uk-nav .MuiSvgIcon-root-1 {
  font-size: 30px;
}

/* HOME PAGE */

.home {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  max-width: 400px;
  padding: 20px;
  width: 100%;
}

/* JOIN & CREATE ROOM PAGES */

.join-room, .create-room {
  padding: 20px;
}

/* ROOM PAGE */

.room-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 12px 20px 20px 20px;
  margin-bottom: 106px;
  /* height of fixed bottom button */
  overflow-y: auto;
}

.room-content__participant-list {
  flex: 1;
  overflow-y: auto;
}

.participant-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0;
}

/* TOAST NOTIFICATIONS */

.uk-notification-message {
  font-size: 15px;
}

.uk-notification-message-success {
  background: #2da779;
  color: #333;
}

.uk-notification-message-danger {
  background: #D14D50;
  color: #efefef;
}

.uk-notification-message-danger .uk-icon {
  color: #efefef;
}

/* INPUTS, BUTTONS, TOGGLES */

form>h3 {
  margin-bottom: 20px;
}

input, select {
  font: inherit;
  color: #e5e5e5;
  background-color: rgba(255, 255, 255, 0.05);
  border: 0;
  border-radius: 1px;
  vertical-align: middle;
  display: inline-block;
  margin: 10px 0;
  padding: 0 10px;
  width: 100%;
  height: 40px;
  transition: .2s ease-in-out;
  transition-property: color, background-color, border;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
}

input:focus, select:focus {
  outline: 0;
}

.form-field {
  padding-bottom: 16px;
}

.uppercase-input {
  text-transform: uppercase;
}

label {
  color: #bcbcbc;
  letter-spacing: 2px;
  font-size: .875rem;
  margin-bottom: 5px;
}

.uk-tab>.uk-active>a {
  color: #e5e5e5;
  border-color: #3a7bd5;
}

.uk-position-center-left, .uk-position-center-right {
  margin: 15px 0 !important;
}

/* BALLOT SHEET */

.ballot-list {
  background-color: #3D4045;
  margin: 0;
  padding: 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 1px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 100%;
  max-height: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: max-height .5s cubic-bezier(0.820, 0.085, 0.395, 0.895);
  box-sizing: border-box;
}

.ballot-list--visible {
  max-height: 100%;
  overflow-y: auto;
}

.ballot-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  list-style: none;
  padding: 0 20px;
  margin: 0 auto;
  transition: background-color 0.3s ease;
}

.ballot-list-item--close {
  padding: 10px 20px;
}

.ballot-list-item:last-child {
  margin-bottom: 10px;
}

.ballot-list-item--selected, .ballot-list-item:hover:not(.ballot-list-item--close) {
  background-color: #484A4F;
}

.icon {
  fill: white;
  height: 24px;
  width: 24px;
}

.icon--large {
  height: 30px;
  width: 30px;
}

.icon--small {
  height: 16px;
  width: 16px;
}

.icon--green {
  fill: #43B581;
}

.icon--blue {
  fill: #00d2ff;
}

.uk-offcanvas-close {
  padding: 0;
}